<template>
    <div class="container">
        <b-tabs>
            <b-tab>
                <template #title>
                    <feather-icon icon="UserIcon" />
                    <span>Singel User</span>
                </template>
                <b-card class="card">
                    <b-row class="justify-content-center">
                        <b-col cols="7">
                            <b-form-group label="Choose a User" label-for="user">
                                <v-select v-model="userIds" :loading="isLoading" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="users" label="user_name" :clearable="false" input-id="user" @search="filterUsers" placeholder="select a user..." />
                            </b-form-group>
                            <b-row class="justify-content-center">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" @click="ShowHandler"> Show </b-button>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card v-if="usersWalletData">
                    <b-row class="justify-content-center mb-4">
                        <b-col cols="4" class="text-center">
                            <b-avatar size="150" :src="usersWalletData.user.thumbnail" />
                            <h3>{{ usersWalletData.user.user_name }}</h3>
                            <small class="text-gray font-weight-bolder">{{ usersWalletData.user.first_name }}</small> <small class="text-gray font-weight-bolder">{{ usersWalletData.user.last_name }}</small>
                            <div class="cursor-pointer mt-1">
                                <b-img v-if="usersWalletData.user.badges.includes('Celebrity')" class="mb-1 mb-sm-0" height="20" src="./images/celebrity.png" alt="Left image" />
                                <b-img v-if="usersWalletData.user.badges.includes('Corporate')" class="mb-1 mb-sm-0 ml-1" height="20" src="./images/corporate.png" alt="Left image" />
                                <b-img v-if="usersWalletData.user.badges.includes('Fashionista')" class="mb-1 mb-sm-0 ml-1" height="20" src="./images/fashionista.png" alt="Left image" />
                                <b-img v-if="usersWalletData.user.badges.includes('Verification')" class="mb-1 mb-sm-0 ml-1" height="20" src="./images/Badge.png" alt="Left image" />
                                <b-img v-if="usersWalletData.user.badges.includes('VIP')" class="mb-1 mb-sm-0 ml-1" height="20" src="./images/vip.png" alt="Left image" />
                            </div>
                        </b-col>
                        <b-col cols="4" class="text-center">
                            <h2 class="font-weight-bolder mb-3">Wallet Balance</h2>
                            <span v-if="walletCurrencyBalance" class="wallet-balance">
                                {{ walletCurrencyBalance.value }} <small>{{ walletCurrencyBalance.key }}</small>
                            </span>
                            <span v-else-if="walletCurrencyBalance && walletCurrencyBalance.key === 'NojoumCoin'"> {{ walletCurrencyBalance.key }} <b-avatar class="mb-50" src="./images/COIN.png" /> </span>
                            <span v-else class="wallet-balance"> {{ usersWalletData.wallet_balance.NojoumCoin }} <b-avatar class="mb-50" src="./images/COIN.png" /> </span>
                            <b-row class="justify-content-center mt-4">
                                <b-col cols="5">
                                    <v-select v-model="walletCurrencyBalance" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Change currency" :options="walletCurrencyBalanceOption" label="key" @input="showtransactionlist" />
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="4">
                            <b-row class="justify-content-center">
                                <h2 class="font-weight-bolder mb-3">Give bonus to user</h2>
                            </b-row>
                            <b-row class="justify-content-center mt-4">
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="relief-danger" pill @click="bonusModalShow = !bonusModalShow"> Charge<feather-icon icon="PlusIcon" /> </b-button>
                            </b-row>
                            <b-modal v-model="bonusModalShow" title="Charge users wallet" modal-class="modal-danger" ok-title="Close" ok-only centered>
                                <b-row class="justify-content-center">
                                    <label for="bonus-amount">Amount</label>
                                    <b-form-input id="bonus-amount" v-model="bonusAmount" autofocus />
                                    <label for="bonus-description" class="mt-1">Description</label>
                                    <b-form-input id="bonus-description" v-model="bonusDescription" autofocus />
                                    <b-button class="mt-2" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="danger" @click="chargeBonusHandler"> Charge<feather-icon icon="PlusIcon" /> </b-button>
                                </b-row>
                            </b-modal>
                        </b-col>
                    </b-row>
                    <b-row class="mt-5 justify-content-center">
                        <h4 class="font-weight-bolder mt-4">Transactions</h4>
                        <b-table ref="refUserListTable" class="position-relative pb-3" :items="userTransaction" responsive :fields="tableColumns" :per-page="perPage" primary-key="id" show-empty empty-text="No matching records found">
                            <template #cell(transaction_time)="data">
                                <div class="text-nowrap">
                                    <span class="font-weight-bolder">{{ data.item.transaction_time.date }}-{{ data.item.transaction_time.time }}</span>
                                </div>
                            </template>
                        </b-table>
                    </b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageUsers({ page: $event, itemsPerPage: perPage })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-card>
            </b-tab>
            <b-tab>
                <template #title>
                    <feather-icon icon="UsersIcon" />
                    <span>Multiple users</span>
                </template>
                <b-card>
                    <b-row>
                        <b-col cols="10">
                            <b-form-group label="Users" label-for="user">
                                <v-select v-model="form.user_ids" :loading="isLoading" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="users" label="user_name" :clearable="false" input-id="user" @search="filterUsers" multiple placeholder="You Can Select Multiple Users..." />
                            </b-form-group>
                        </b-col>

                        <b-col cols="2">
                            <b-form-checkbox v-model="form.send_to_all" :value="true" class="custom-control-danger mt-2"> Send to All Users </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="7" sm="6" md="8">
                            <label>Description</label>
                            <textarea ref="textBox" v-model="form.text" class="textBoxArea" placeholder="Enter a description if you want..."></textarea>
                        </b-col>
                        <b-col cols="3" sm="6" md="4">
                            <label>Amount</label>
                            <b-form-input v-model="form.amount" placeholder="Enter the amount" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="2">
                            <b-button @click="emojiBtn" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-warning" class="btn-icon rounded-circle">
                                <feather-icon class="icon" icon="SmileIcon" />
                            </b-button>
                        </b-col>
                        <b-col cols="7">
                            <VEmojiPicker class="emojiBox" v-if="emojiAllowed" @select="insertEmoji" />
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" @click="sedMultipleBonus">Send</b-button>
                    </b-row>
                </b-card>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { BModal, BTable, BAvatar, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BOverlay, BPagination } from "bootstrap-vue";
import { ref, onBeforeUnmount, onMounted, watchEffect } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import UserRepository from "@/abstraction/repository/userRepository";
import useWalletList from "./useWalletList";
import { VEmojiPicker } from "v-emoji-picker";
const userRepository = new UserRepository();

export default {
    components: {
        VEmojiPicker,
        BModal,
        BPagination,
        BTable,
        BAvatar,
        BOverlay,
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        const isLoading = ref(false);
        const users = ref();
        const userIds = ref();
        const usersWalletData = ref();
        const walletCurrencyBalance = ref();
        const walletCurrencyBalanceOption = ref();
        const bonusDescription = ref();
        const bonusModalShow = ref(false);
        const bonusAmount = ref();
        const form = ref({
            send_to_all: false,
            text: "",
            // user_ids:"",
        });
        const emojiAllowed = ref(false);
        const { fetchUserWallet, fetchUserTransactionList, userId, userTransaction, tableColumns, perPage, currentPage, perPageOptions, pagination, giveBonusToUser, sendMultipleBonuses } = useWalletList();

        const filterUsers = async (search) => {
            const filters = {
                user_name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            try {
                loadUsers(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
            }
        };
        const loadUsers = async (param = {}) => {
            const resource = await userRepository.index(param);
            users.value = resource.data;
        };
        loadUsers();
        const ShowHandler = () => {
            fetchUserWallet(userIds.value.id).then((res) => {
                usersWalletData.value = res.data.data;
                walletCurrencyBalanceOption.value = Object.entries(usersWalletData.value.wallet_balance).map(([key, value]) => ({ key, value }));
                console.log(usersWalletData.value);
            });
            userId.value = userIds.value.id;
            fetchUserTransactionList().then((res) => {
                console.log(userTransaction);
            });
        };
        const showtransactionlist = () => {
            console.log(walletCurrencyBalance.value);
        };
        const chargeBonusHandler = () => {
            let data = {
                user_id: usersWalletData.value.user.id,
                wallet_id: usersWalletData.value.id,
                amount: bonusAmount.value,
                description:bonusDescription.value,
            };
            giveBonusToUser(data).then(() => {
                fetchUserWallet(userIds.value.id).then((res) => {
                    usersWalletData.value = res.data.data;
                    walletCurrencyBalanceOption.value = Object.entries(usersWalletData.value.wallet_balance).map(([key, value]) => ({ key, value }));
                    console.log(usersWalletData.value);
                });
            });
        };
        const emojiBtn = () => {
            emojiAllowed.value = !emojiAllowed.value;
        };
        const textBox = ref(null);
        const text = ref("");
        const titleTextBox = ref(null);
        const titleEmojiAllowed = ref(false);
        const prevSelectionStart = ref(null);
        function insertEmoji(emoji) {
            const startPos = textBox.value.selectionStart;
            const endPos = textBox.value.selectionEnd;
            const newText = form.value.text.slice(0, startPos) + emoji.data + form.value.text.slice(endPos);
            form.value.text = newText;
            focusEmoji(startPos);
        }
        function focusEmoji(startPos) {
            textBox.value.focus();
            textBox.value.selectionStart = startPos;
        }
        //

        const titleEmojiBtn = () => {
            titleEmojiAllowed.value = !titleEmojiAllowed.value;
        };
        function insertTitleEmoji(emoji) {
            const startPos = titleTextBox.value.selectionStart;
            const endPos = titleTextBox.value.selectionEnd;
            const newText = form.value.title.slice(0, startPos) + emoji.data + form.value.title.slice(endPos);
            form.value.title = newText;
            focusTitleEmoji(startPos);
        }
        function focusTitleEmoji(startPos) {
            titleTextBox.value.focus();
            titleTextBox.value.selectionStart = startPos;
        }
        const sedMultipleBonus = () => {
            if (form.value.user_ids) {
                form.value.user_ids = form.value.user_ids.map((item) => {
                    return item.id;
                });
            }
            console.log(form.value);
            sendMultipleBonuses(form.value).then((res) => {
                console.log(res);
            });
        };
        return {
            sedMultipleBonus,
            showtransactionlist,
            users,
            userIds,
            filterUsers,
            isLoading,
            ShowHandler,
            usersWalletData,
            walletCurrencyBalance,
            walletCurrencyBalanceOption,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            pagination,
            userTransaction,
            bonusModalShow,
            bonusAmount,
            chargeBonusHandler,
            isLoading,
            form,
            emojiBtn,
            emojiAllowed,
            insertEmoji,
            textBox,
            text,
            prevSelectionStart,
            bonusDescription,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.icon {
    margin: 1px;
}
.textBoxArea {
    min-width: 900px;
    min-height: 150px;
    border: 1px solid rgba(204, 204, 204, 0.165);
    border-radius: 10px;
    background-color: rgba(240, 248, 255, 0);
    color: aliceblue;
}
.wallet-balance {
    font-size: 50px;
    margin-top: 60px;
}
</style>
